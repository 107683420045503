import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "images"
    }}>{`Images`}</h1>
    <h2 {...{
      "id": "responsive"
    }}>{`Responsive`}</h2>
    <p>{`Images are made responsive with `}<inlineCode parentName="p">{`.img-fluid`}</inlineCode>{`. This applies max-width: 100%; and height: auto; to the image so that it scales with the parent element.`}</p>
    <div className='wrapper-figure border-gray p-3 border-radius' style={{
      "border": "1px solid transparent",
      "maxWidth": "500px"
    }}>
  <figure>
    <img src='https://portal.squidit.com.br/assets/img/jpg/default-image.jpg' alt='Default Image' title='Default Image' className='img-fluid' />
  </figure>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      